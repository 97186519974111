import React, { useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';

const formatPhone = () => '!6&25&83&!4&49'
  .replaceAll('!', '0')
  .replaceAll('&', ' ');

const Footer = () => {
  const [showPhone, setShowPhone] = useState(false);
  return (
    <div className="footer-section">
      <Container fluid>
        <Row>
          <Col>
            <p className="content">
              Pour planifier votre première rencontre gratuite, contactez-moi dès maintenant :)
            </p>
            <div className="link-icon">
              {!showPhone && (
              <div role="button" tabIndex={0} onClick={() => setShowPhone(true)} onKeyPress={() => setShowPhone(true)}>
                <img className="icon mb-4" src="/images/phone.svg" alt="téléphone" />
              </div>
              )}
              {showPhone && <div className="content mb-4">{formatPhone()}</div>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="px-0">
            <div className="content content-sm px-3 px-md-5">
              Retrouvez @lentrepreneuse.feline sur
              {' '}
              <a className="link footer-link" href="https://www.instagram.com/lentrepreneuse.feline/?hl=fr" target="_blank" rel="noreferrer">Instagram</a>
              {' '}
              |
              {' '}
              <a className="link footer-link" href="https://www.facebook.com/profile.php?id=61552566132599" target="_blank" rel="noreferrer">Facebook</a>
              {' '}
              |
              {' '}
              <a className="link footer-link" href="https://www.tiktok.com/@lentrepreneuse.feline" target="_blank" rel="noreferrer">TikTok</a>
            </div>
            <div className="content content-sm px-3 px-md-5">
              <a className="link footer-link" href="https://www.nekolie.fr" target="_blank" rel="noreferrer">Ma boutique en ligne</a>
              {' '}
              |
              {' '}
              <a className="link footer-link" href="https://www.youtube.com/channel/UCc1mmyydtC07fNspOjJiALg/about" target="_blank" rel="noreferrer">YouTube</a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
